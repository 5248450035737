/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import classnames from 'classnames'
import { useEffect } from 'react'
import styles from './styles.module.css'
import chevrondown from '../../../public/images/feather-chevron-down@2x.png'
// import {
//   PropertyListingsConsumer
// } from '../../context/PropertyListingsProvider'
// const { filterListings, updateFilter, stateList } = this.props
const pageSizeStatic = 12;
const DefaultState = {
  priceFrom: 0,
  lease: '',
  sortOrder: '',
  sortOrders: '',
  start: 1,
  pageSize: pageSizeStatic,
  sortColumn: "",
  sortColumnDirection: 'asc',
  searchValue: '',
  PropertyTypeId: 0,
  PriceUnder: 0,
  MarketingMaterialId: 0,
}

class Filter extends React.Component {

  state = Object.assign({}, DefaultState)

  handleChange = (prop, value) => {
    // console.log("value : ", value)
    this.setState({
      [prop]: value
    })
  }

  render() {
    // const content = React.useContext(PropertyListingsConsumer);
    // console.log("content : ",content)
    // const { stateList } = content;


    const containerClasses = classnames('container', 'mb-1', styles.container)
    const formClasses = classnames('form-horizontal', styles.form)
    const { filterListings, updateFilter } = this.props
    const { searchValue, PropertyTypeId, PriceUnder, MarketingMaterialId, sortColumn, start, pageSize } = this.state
    // const { searchValue, PropertyTypeId, PriceUnder, MarketingMaterialId, sortColumn ,start,pageSize} = stateList
    // console.log("stateList : ",stateList)

    const handleSubmit = event => {
      // 👇️ prevent page refresh
      event.preventDefault();
    };
    return (
      <aside className="sticky">
        <div className="pageContainer">
          <form
            onChange={() => setTimeout(() => updateFilter(this.state), 0)}
            className={formClasses}
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="columns text-center">
              <div className="column col-5 col-xs-12">
                <div className="form-group">
                  <div className="col-9 col-sm-12 mb-1">
                    <input
                      className="form-input border_radius_2 filterElements"
                      type="text"
                      id="searchValue"
                      placeholder="Search"
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                          
                          this.handleChange('searchValue', document.getElementById("searchValue").value);
                        setTimeout(() => updateFilter(this.state), 0)
                        }
                      }}
                      // value={}
                      onChange={(event) => {
                        if (!event.target.value) {
                          this.handleChange('searchValue', event.target.value)
                        }
                        // 
                      }

                      }
                    />
                  </div>
                  <div className="col-3 col-sm-12 mb-1 dpl-1">
                    <button
                      style={{ 'cursor': 'pointer' }}
                      className="btn-sm btn_style border_radius_2 filterElements"
                      type="button"
                      id="searchValue"
                      placeholder="Search"
                      onClick={(event) => {
                        this.handleChange('searchValue', document.getElementById("searchValue").value);
                        setTimeout(() => updateFilter(this.state), 0)
                      }}
                    >Search</button>
                  </div>
                </div>
              </div>

              <div className='column col-7 col-xs-12'>
                <div className='columns'>
                  <div className="column col-4 col-xs-12 pl-0 pr-0 mb-1 additional_padding ">
                    <div className="form-group">
                      <div className="col-12 col-sm-12">
                        <select
                          style={{ background: '#fff url("' + chevrondown + '") no-repeat right .35rem center/.6rem .4rem' }}
                          className="form-select theme_color font_size_14 border_radius_2 filterElements"
                          id="MarketingMaterialId"
                          value={MarketingMaterialId}
                          onChange={event =>
                            this.handleChange('MarketingMaterialId', event.target.value)
                          }
                        >
                          {/* <option value="">For Lease</option> */}
                          {filterListings?.marketingMaterialId?.map((pc, idx) => (
                            <option key={idx} value={pc.id}>{pc.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column col-4 col-xs-12 pr-0 pl-0 mb-1 additional_padding">
                    <div className="form-group">
                      <div className="col-12 col-sm-12">
                        <select
                          style={{ background: '#fff url("' + chevrondown + '") no-repeat right .35rem center/.6rem .4rem' }}
                          className="form-select theme_color font_size_14 border_radius_2 filterElements"
                          id="PropertyTypeId"
                          value={PropertyTypeId}
                          onChange={event =>
                            this.handleChange('PropertyTypeId', Number(event.target.value))
                          }
                        >
                          {/* <option value="">Property Type</option> */}
                          {filterListings?.propertyType?.map((pc, idx) => (
                            <option key={idx} value={pc.id}>{pc.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
               

                  <div className="column col-4 col-xs-12 pr-0 pl-0 additional_padding">
                    <div className="form-group">
                      <div className="col-12 col-sm-12">
                        <select
                          style={{ background: '#fff url("' + chevrondown + '") no-repeat right .35rem center/.6rem .4rem' }}
                          className="form-select theme_color font_size_14 border_radius_2 filterElements"
                          id="sortColumn"
                          value={sortColumn}
                          onChange={event =>
                            this.handleChange('sortColumn', event.target.value)
                          }
                        >
                          {/* <option value="">Sort By</option> */}
                          {filterListings?.sortColumn?.map((pc, idx) => (
                            <option key={idx} value={pc.id}>{pc.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
      </aside>
    )
  }
}

export default Filter

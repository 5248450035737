import * as React from 'react'
import styles from './styles.module.css'



function Header(headerData) {
  if (!headerData) {
    return null
  }
  return (
    <div id="header" className='boxshadow-bottom'>
      <div className="pageContainer">
        <div className="columns vertical_align_center" >
          <div className='column col-3 col-xs-3'>
            <img className='img-responsive companyLogo' src={headerData?.headerData?.logo} />
          </div>
          <div className='column col-6 col-xs-9'>
            <p className='banner-text '>{headerData?.headerData?.name}</p>
          </div>
          <div className='column col-3 col-xs-12'></div>
        </div>
      </div>
    </div>
  )

}



export default Header

import * as React from 'react'
import classnames from 'classnames'
import { useState, useEffect, useRef } from 'react';
import restApi from "../../serverhooks/rest-apis/restApis";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import 'font-awesome/css/font-awesome.min.css';
import Header from '../../components/header';
import Footer from '../../components/footer';
import MapContainer from '../map/MapContainer';
import Map from '../map'
import { scroller } from "react-scroll";
import Fullscreen from "./CustomizeReactImageGallery/Fullscreen";
import styles from './styles.module.css'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useNavigate, } from '@reach/router'
import { textTransform } from '@mui/system';
import $ from 'jquery';
function PropertyDetails({ iframe, propertyId }) {
  let navigate = useNavigate();
  const center = { lat: 25.761679, lng: -80.259387 };
  const zoom = 16;
  const { getDetails } = restApi();
  const [get_Detail, set_Detail] = useState({});
  const [headerData, set_header] = useState({});
  if (!propertyId) {
    return null
  }

  useEffect(() => {

    getDetails(propertyId).then((filterData) => {
      set_Detail(filterData.data);
      set_header(filterData.data.company)
      showtabonly('tabs-OverviewDiv');
    })

    scrollToSection('topzero');
  }, [propertyId]);

  function setContactCardHeight(){
    let screenWidth = screen.width
    if(screenWidth > 414){
    var maxHeight = 0
    $('.contacts-card').find('.card-body').each(function(index) {
    if($(this).height() > maxHeight){
    maxHeight = $(this).height()
    }
    $('.contacts-card').find('.card-body').height(maxHeight)
  });
}
  }

  function setHeightIframe(offsetHeight) {
    console.log('sergeight', offsetHeight)
    window.parent.postMessage(["setHeight", offsetHeight], "*");
  }
  function showtabonly(classname) {
    var elements = document.getElementsByClassName("tabsGeneral");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.style.display = "none";
    }

    
    var offsetHeight = 0;
    var headers = document.getElementsByClassName("headerDiv");
    
    var maxheaderHeight = 0;
    for (let index = 0; index < headers.length; index++) {
      const header = headers[index];
      if (header.offsetHeight > maxheaderHeight) {
        maxheaderHeight = header.offsetHeight;
      }
    }
    offsetHeight += maxheaderHeight;
    elements = document.getElementsByClassName(classname);
    if('tabs-spaceDiv' ==classname){
      elements[0].style.display = "block"
    }else{
      elements[0].style.display = "flex"
    }
    
    offsetHeight += elements[0].offsetHeight;
    // for (let index = 0; index < elements.length; index++) {
    //   const element = elements[index];

    //   if ('tabs-OverviewDiv' == classname) {
    //     element.style.display = "flex";
    //   } 
    //   //else {
    //    // element.style.display = "unset";
    //  // }
    //   offsetHeight += element.offsetHeight;
    // }

    // if ('tabs-OverviewDiv' == classname) {
    //   $(".tab-block a:nth-child(1)").addClass('active');
    //   elements[0].style.display = "flex";

    // } else {
    //   $(".tab-block a:nth-child(1)").removeClass('active');
    //   elements[0].style.display = "unset";
    // }
    
    setHeightIframe(offsetHeight + 60);
    setContactCardHeight()
  }
  function scrollToSection(param, num) {
    if (param == "topzero") {
      window.scrollTo({ top: 0 });
    }
    else if (param == "top") {
      // window.scrollTo(0, 0)
      window.scrollTo({ top: 220, behavior: 'smooth' });

    } else {
      scroller.scrollTo(param, {
        duration: 400,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }


    // Add active class to the current button (highlight it)
    var header = document.getElementById("mydivactive");
    var btns = header.getElementsByClassName("tablinks");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }

  };

  function ajaxFileStream(url, mm) {
    let btndownload =  document.getElementsByClassName("download-document");
    for (let index = 0; index < btndownload.length; index++) {
    btndownload[index].innerHTML ='<i class="fa fa-spinner fa-spin"></i> Download document';
      
    }
   
    console.log('download url', url);
    var oReq = new XMLHttpRequest();


    let filename = "";
    if (mm == "1") {
      filename = "OfferingMemorandum.pdf";
    } else if (mm == "2") {
      filename = "SalesTeaser.pdf";
    } else {
      filename = "LeasingPackage.pdf";
    }
    oReq = new XMLHttpRequest();
    oReq.onprogress = function (oEvent) {

    }
    oReq.open("GET", url, true);
    oReq.responseType = "arraybuffer";
    oReq.onload = function (oEvent) {
      var blob = new Blob([oReq.response], { type: "application/pdf" });
      var dataUrl = URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = dataUrl;
      a.download = filename;;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      let btndownload =  document.getElementsByClassName("download-document");
      for (let index = 0; index < btndownload.length; index++) {
      btndownload[index].innerHTML ='Download document';
        
      }
    };
    oReq.send();
  }

  function renderFullscreenButton(onClick, isFullscreen) {
    return (
      <Fullscreen onClick={() => {

        if (isFullscreen) {
          document.body.style.overflow = "scroll"

        } else {
          scrollToSection('topzero');
          document.body.style.overflow = "hidden"
        }

        onClick();
      }} isFullscreen={isFullscreen} />
    );
  }
  return (
    <>
      <div className="headerDiv desktop-header">
      {iframe != 'true' && <Header headerData={headerData} />}
        
        <div className="columns ">
          <div className="column col-12 col-xs-12" style={{ padding: 0 }}>
            <div className="topnav " id='mydivactive'>
              <div className='pageContainer propertyInfoHeader'>
                <div className='columns'>
                  <div className='column'>
                    <button onClick={() => navigate(-1)} className='back-button'>Go Back to Listings</button>
                    <p className='property-title'>
                      {get_Detail?.propertyInfo?.name}
                    </p>
                    <p className='property-address'>
                      {get_Detail?.propertyInfo?.address1}, {get_Detail?.propertyInfo?.address2}

                    </p>
                  </div>
                </div>


              </div>
              <div className='sticky '>
                <div className='bottom-line'></div>
                <div className='pageContainer '>
                  <div className='columns'>
                    <div className='column col-md-8 col-sm-8 col-xs-8 col-lg-8'>
                      <ul className="tab tab-block">
                        <a className='tablinks tablinkOverview active' id='1' onClick={() => showtabonly('tabs-OverviewDiv')}>Overview</a>
                        <a style={{ 'display': (get_Detail?.packageType == 3 && get_Detail?.spacesAvailable?.length > 0) == true ? 'block' : 'none' }} className='tablinks' id='2' onClick={() => showtabonly('tabs-spaceDiv')}>Spaces</a>

                        <a className='tablinks' id='3' onClick={() => showtabonly('tabs-map')}>Map</a>
                        <a className='tablinks' id='4' onClick={() => showtabonly('tabs-contacts')}>Contacts</a>
                      </ul>
                    </div>
                    <div className='column  col-md-4 col-sm-4 col-xs-4  col-lg-4'>
                      <button onClick={() => { ajaxFileStream(get_Detail?.downloadLink, get_Detail?.packageType) }}
                        className='btn btn-default download-document '>Download document</button>
                    </div>
                  </div>
                </div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="headerDiv mobile-header">
      {iframe != 'true' && <Header headerData={headerData} />}
        <div className="columns ">
          <div className="column col-12 col-xs-12" style={{ padding: 0 }}>
            <div className="topnav " id='mydivactive'>
              <div className='pageContainer propertyInfoHeader'>
                <div className='columns'>
                  <div className='column'>
                    <button onClick={() => navigate(-1)} className='back-button'>Go Back to Listings</button>

                  </div>
                  <div className='column'>
                    <button onClick={() => { ajaxFileStream(get_Detail?.downloadLink, get_Detail?.packageType) }}
                      className='btn btn-default download-document '>Download document</button>
                  </div>
                </div>


              </div>
              <div className='sticky '>
                <div className='pageContainer leasing_heading'>
                  <div classname='columns '>
                    <div classname='column pl-1 pr-0 '>
                      <p className='property-title'>
                        {get_Detail?.propertyInfo?.name}
                      </p>
                      <p className='property-address'>
                        {get_Detail?.propertyInfo?.address1}, {get_Detail?.propertyInfo?.address2}

                      </p>
                    </div>
                  </div>
                  <div className='bottom-line'></div>
                  <div className='columns'>
                    <div className='column'>
                      <ul className="tab tab-block">
                        <a className='tablinks tablinkOverview' id='1' onClick={() => showtabonly('tabs-OverviewDiv')}>Overview</a>
                        <a style={{ 'display': (get_Detail?.packageType == 3 && get_Detail?.spacesAvailable?.length > 0) == true ? 'block' : 'none' }} className='tablinks' id='2' onClick={() => showtabonly('tabs-spaceDiv')}>Spaces</a>
                        <a className='tablinks' id='3' onClick={() => showtabonly('tabs-map')}>Map</a>
                        <a className='tablinks' id='4' onClick={() => showtabonly('tabs-contacts')}>Contacts</a>
                      </ul>
                    </div>
                  </div>
                  <div className='bottom-line'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pageContainer ">
      <div className="columns tabsGeneral tabs-OverviewDiv">
        <div className="columns  col-12 col-xs-12">
          
          <div className="columns column col-5 col-md-12 col-xs-12">
            <div className="container">
              <h3 className='section-title theme_color OverviewDiv' style={{ 'marginTop': '5px' }} >Property Facts</h3>
              {get_Detail?.propertyFacts?.map((data, ind) => {
                    if (data?.value != '0.00' && data?.value != '0' && data?.value != 0 && data?.value != '0.00 Ft'&& data?.value != '0.00 SqFt') {
                      return <div className="columns ">
                      <div className="column col-4 col-xs-6" >
                        <p className='properties'>{data?.name?.replace(':', '')}</p>
                      </div><div className="column col-8 col-xs-6">
                        <p className='properties black'>{data?.value}</p>
                      </div>
                      </div>
                    }                
                })}
            </div>
          </div>
          {(() => {
            if (get_Detail?.images?.length > 0) {
              return (
                <div className="columns col-7 col-md-12 col-xs-12">
                  <div className="columns column col-12 col-xs-12">&nbsp;</div>
                  <ImageGallery
                    useBrowserFullscreen={false}
                    items={get_Detail.images}
                    showBullets={true}
                    showIndex={true}
                    showThumbnails={true}
                    lazyLoad={true}
                    showPlayButton={false}
                    thumbnailPosition="bottom" //top, right, bottom, left
                    thumbnailWidth={260}
                    thumbnailHeight={130}
                    renderFullscreenButton={renderFullscreenButton}
                  />
                </div>
              );
            }
          })()}

        </div>

        <div className="columns">
          {(() => {
            if (get_Detail?.highlights) {
              return (
                <div className="column col-12 col-xs-12 mt-2">
                  {(() => {
                  if(get_Detail?.packageType == 3){
                    return (<h3 className='section-title theme_color'>Property Highlights</h3>)
                  }else{
                    return (<h3 className='section-title theme_color'>Investment Highlights</h3>)
                  }
                })()}
                  <div style={{ 'paddingLeft': '22px' }}>
                    <ul className='descp' dangerouslySetInnerHTML={{ __html: get_Detail?.highlights }}></ul>
                  </div>

                </div>
              );

            }
          })()}

          {(() => {
            if (get_Detail?.description) {
              return (
                <div className="column col-12 col-xs-12 mt-2">
                  <h3 className='section-title theme_color'>Description</h3>
                  <div className='descp descp-list-style-none' dangerouslySetInnerHTML={{ __html: get_Detail?.description }}></div>
                </div>
              );

            }
          })()}
          {(() => {
            if (get_Detail?.location) {
              return (
                <div className="column col-12 col-xs-12 mt-2">
                  <h3 className='section-title theme_color'>Location Highlights</h3>
                  <div style={{ 'paddingLeft': '22px' }}>
                  <ul className='descp  descp-list-style-none bullets_add' dangerouslySetInnerHTML={{ __html: get_Detail?.location }}></ul>
                  </div>
                </div>
                );

            }
          })()}
        </div>
        </div>
        <div className='tabsGeneral tabs-spaceDiv'>
          {(() => {
            if (get_Detail?.spacesAvailable?.length > 0) {
              return (
                <div className="columns space spaceDiv">
                  <div className='column col-12 col-xs-12'>
                    <p className='section-title  theme_color'>Spaces Available</p>
                    {get_Detail?.spacesAvailable?.map((spaces) => (
                      <div className="card SPAvail">
                        <div className="columns">
                          <div className='p-1 col-12 col-xs-12'>
                            <p className='section-subtitle2 theme_color'>
                              Suite {spaces?.suite}
                            </p>
                          </div>
                          <div className='p-1 col-2 col-xs-6'>
                            <p className="py-1 color_grey sa-title">Unit Size</p>
                            <p className="py-1 color_grey sa-title">Price</p>
                            <p className="py-1 sa-title">Lease Type</p>
                            <p className="py-1 sa-title">Term</p>
                            {/* <p className="py-1 color_grey">Price Per SF</p> */}
                          </div>
                          <div className='p-1 col-2 col-xs-6'>
                            <p className="py-1 color_black sa-content">{spaces?.size}</p>
                            <p className="py-1 color_black sa-content">{spaces?.price}</p>
                            <p className="py-1 color_black sa-content">{spaces?.type}</p>
                            <p className="py-1 color_black sa-content">{spaces?.term}</p>
                            {/* <p className="py-1 color_black">$1,247.50</p> */}
                          </div>
                          <div className="px-1 col-12 col-xs-12">
                            <p className='sales-note-desc'>{spaces?.description} </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );

            }
          })()}
        </div>
        <div className='columns map tabsGeneral tabs-map'>
          <div className='column col-12 col-xs-12'>
            <p className="section-title  theme_color">Map</p>
            <div className='card' style={{ padding: '0 0 13px 17px' }}>
              <div className='columns'>
                <div className='column col-2 col-xs-3'>
                  <p style={{ 'text-transform': 'capitalize' }} className='properties2'>City</p>
                </div>
                <div className='column col-2 col-xs-3'>
                  <p style={{ 'textTransform': 'capitalize' }} className='prop2'>{get_Detail?.propertyInfo?.city}</p>
                </div>
                <div className='column col-2 col-xs-3'>
                  <p className='properties2'>Zip Code</p>
                </div>
                <div className='column col-2 col-xs-3'>
                  <p className='prop2'>{get_Detail?.propertyInfo?.zip}</p>
                </div>

              </div>
              <div className='columns'>
                <div className='column col-2 col-xs-3'>
                  <p className='properties2'>County</p>
                </div>
                <div className='column col-2 col-xs-3'>
                  <p className='prop2'>{get_Detail?.propertyInfo?.county}</p>
                </div>
              </div>
            </div>
            {/* <Map address={get_Detail?.mapCoordinates} /> */}

         
              {get_Detail && <MapContainer center={{ lat: get_Detail?.mapCoordinates?.split(',')[0], lng: get_Detail?.mapCoordinates?.split(',')[1] }} />}

     
          </div>

        </div>
        <div className='columns tabsGeneral tabs-contacts'>
          <div className='column col-12 col-xs-12'>
            <p className="section-title contacts theme_color ">Contacts</p>

            <div className='columns mt-1'>
                  {get_Detail?.contacts?.map((data, ind) => (

                    <div className="column col-4 col-sm-6 col-xs-12 contact-column">
                      <div className='card rounded contacts-card'>
                        <div className="header theme_background_color">
                          <div className="card-title">Contact Information</div>
                        </div>
                        <div className="card-body border_none">
                          <p className='small-card-p'>{data?.title}</p>
                          <div className='columns flex_flow_change'>
                          <div className="column profileImage">
                            {!data?.profilePhoto.includes('images/noImage.png') &&
                                <div>
                                <img onLoad={(event) => { }} onError={(event) => event.target.parentElement.style.display = 'none'} className="img-responsive" src={`${data?.profilePhoto}`} />  
                                </div>
                            }
                            </div>
                            <div className="column profile_content">
                              <p className='small-card-text-bold'>{data?.fullname}</p>
                              <p className='small-card-text display-flex'><span className="address_span1"><div className='address-field-width'> Email:</div></span><span> {data?.email}</span></p>
                              {<p className='small-card-text display-flex'><span className="address_span1"> <div className='address-field-width'>{data?.phoneType}:</div></span><span> {data?.directphone}</span></p>}
                              {data?.officephone && <p className='small-card-text display-flex'><span className="address_span1"> <div className='address-field-width'> Office:</div></span><span>{data?.officephone}</span></p>}
                              {data?.licenseNumber && <p className='small-card-text display-flex'><span className="address_span1"><div className='address-field-width'>LIC#:</div></span><span>{data?.licenseNumber}</span></p>}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  ))}
              {get_Detail?.company &&
                <div className="column col-4 col-sm-6 col-xs-12 office-information-box contact-column">
                  <div className='card rounded contacts-card'>
                    <div className="header theme_background_color">
                      <div className="card-title">Office Information</div>
                    </div>
                    <div className="card-body border_none"  >
                      <p className='small-card-p'>&nbsp;</p>
                      <div className='columns flex_flow_change'>
                        <div className="column profileImage">
                        {!get_Detail?.company?.logo.includes('images/noImage.png') &&
                                <div>
                                <img onError={(event) => event.target.parentElement.style.display = 'none'} className="img-responsive" src={`${get_Detail?.company?.logo}`} />
                                </div>
                        }
                          
                        </div>
                        <div className="column profile_content">
                          {get_Detail?.company?.name && <p className='small-card-text-bold'>{get_Detail?.company?.name}</p>}
                          {get_Detail?.company?.officeaddress && <p className='small-card-text display-flex'>   
                          <span > {get_Detail?.company?.officeaddress} 
                          <div style={{'display': 'block'}}> <span className='city-name'> {get_Detail?.company?.city && '' +  get_Detail?.company?.city}</span> {get_Detail?.company?.state && ' ' + get_Detail?.company?.state} <span className='remove-space'></span> {get_Detail?.company?.zip && ', ' + get_Detail?.company?.zip}</div></span>
                           
                          </p>}
                          {get_Detail?.company?.officephone && <p className='small-card-text display-flex'><span> {get_Detail?.company?.officephone}</span></p>}
                          {get_Detail?.company?.website && <p className='small-card-text display-flex'><span>{get_Detail?.company?.website}</span></p>}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              }

            </div>

          </div>
          
          
        </div>
      </div>
      {iframe != 'true' && <Footer />}
    </>
  )
  // {setContactCardHeight()}
}

export default PropertyDetails

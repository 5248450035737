import React from "react";

function  Map(address) {
  // const address = "Mason Street, Manchester, Greater Manchester, M4";
  //   t= Map Type. The available options are "m" map, "k" satellite, "h" hybrid, "p" terrain, "e" GoogleEarth.
// z= Sets the zoom level. (Range from 1-20)
// layer= Activates overlay. The available options are "t" traffic, "c" street view.
  // console.log("address : ",address)
  const search = `https://maps.google.com/maps?q=${address.address}&t=&z=16&ie=UTF8&iwloc=&output=embed`;

  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          title={address.address}
          src={search}
          width="100%"
          height="500"
          id="gmap_canvas"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
      </div>
    </div>
  );
}

export default Map;

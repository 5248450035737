import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from '@reach/router'
// import {BrowserRouter as Router} from 'react-router-dom';
import Home from './pages/Home'
import Details from './pages/Details'

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Router>
    <Home path="/:companyId" default />
    <Details path="/details/:propertyId/:iframe" />
  </Router>,
  rootElement
)

// $(window).scroll(function(){
//   var limit=100
//   if (location.href.indexOf("/details/")>0) limit=120;
//   var sticky = $('.sticky'),
//        mainContentDIV = $('#mainContentDIV'),
//       scroll = $(window).scrollTop();
      

//   if (scroll >= limit) {
//     sticky.addClass('fixed');
//     mainContentDIV.addClass('fixedPad');
    
//     $('.OverviewDiv').css('padding-top','90px');
  
//   }
//   else{
//     sticky.removeClass('fixed');
//     mainContentDIV.removeClass('fixedPad');
//     $('.OverviewDiv').css('padding-top','0');
//   }
// });

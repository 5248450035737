import * as React from 'react'
import {useState} from 'react'
import Listing from '../../components/listing'
import {
  PropertyListingsProvider,
  PropertyListingsConsumer
} from '../../context/PropertyListingsProvider'

import Header from '../../components/header'
import Footer from '../../components/footer'
import Filter from '../../components/filter'
import BaseLayout from '../../components/baseLayout'


function Home() {
  const [getLoading,setLoading] = useState(false)
  // const content = React.useContext(PropertyListingsConsumer);
  // const { propertyListings, filterListings, headerData, updateFilter, loadMore, page } = content;
  // console.log("page : ",page)
  return (
    <BaseLayout>
      <PropertyListingsProvider>
        <PropertyListingsConsumer>
          {({ propertyListings, filterListings, headerData, updateFilter,loadPrevious, loadMore,isPreviousPage,isNextPage ,iframe,recordsTotal,paginationText}) => (
            <>
              <div id="headerDiv">
              {iframe != 'true' && <Header headerData={headerData} />}
                <Filter
                  updateFilter={updateFilter}
                  filterListings={filterListings}
                />
              </div>
              <div id="mainContent" className='pageContainer'>
                <div className="columns" >
                  {propertyListings?.map((listing, idx) => (
                    <Listing iframe={iframe} listing={listing} key={idx} />
                  ))}

                </div>
                {
                    paginationText &&
                    <div className='columns' style={{ 'textAlign': 'center', width: '100%', display: 'block' }}>
                    <span style={{opacity:`${isPreviousPage ==true?1:0.5}`}} onClick={function(){
                      
                      if(isPreviousPage && !getLoading){
                        setLoading(true);
                        loadPrevious(setLoading)
                      }else{
                      }
                      
                    }} className='fa fa-chevron-left pagination-button'></span>
                   
                      <span  className='pagination-text'>Displaying {paginationText} of {recordsTotal}</span>
                    
                    
                    <span style={{opacity:`${isNextPage ==true?1:0.5}`}} onClick={function(){
                      
                      if(isNextPage && !getLoading){
                        setLoading(true);
                        loadMore(setLoading)
                      }
                      else{
                      }
                      
                    }} className='fa fa-chevron-right pagination-button'></span>
                </div>
                }
                
             

              </div>
              {
                iframe==false &&  <Footer />
              }
             
            </>
          )}
        </PropertyListingsConsumer>
      </PropertyListingsProvider>
    </BaseLayout>
  )
}

export default Home
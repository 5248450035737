import * as React from 'react'
import PropertyDetails from '../../components/propertyDetails'
import {
  PropertyListingsProvider,
  PropertyListingsConsumer
} from '../../context/PropertyListingsProvider'
// import Header from '../../components/header'
import BaseLayout from '../../components/baseLayout';
import '../../../public/fonts/Roboto/Roboto-Medium.ttf';
function Details({ propertyId, iframe}) {
  if (!propertyId) return null;
  return (
    <BaseLayout>
      <PropertyDetails iframe={iframe} propertyId={propertyId} />
    </BaseLayout>
  )

}

export default Details
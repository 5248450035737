import * as React from 'react'
import restApi from "../serverhooks/rest-apis/restApis";

const DefaultState = {
  propertyListings: [],
  filterListings: [],
  headerData: {},
  filter: {},
  page: 1,
}

const pageSizeStatic = 12;
const { getList, getAllFilter } = restApi();

const PropertyListingsContext = React.createContext(DefaultState)

export const PropertyListingsConsumer = PropertyListingsContext.Consumer

export class PropertyListingsProvider extends React.Component {
  static applyFilter(listings, filter) {
    const { priceFrom, postcode, sortOrder } = filter
    let result = listings
    if (priceFrom) {
      const from = priceFrom
      result = result.filter(item => item.price >= from)
    }
    if (postcode) {
      result = result.filter(item => item.postcode.toLowerCase().startsWith(postcode))
    }
    if (sortOrder) {
      if (sortOrder === 'highestfirst') {
        result = result.sort((a, b) => b.price - a.price)
      }
      if (sortOrder === 'lowestfirst') {
        result = result.sort((a, b) => a.price - b.price)
      }
    }
    return result
  }

  state = DefaultState
  applyIframeHeight(length) {
    // let body = document.body,html = document.documentElement;
    //   let height = Math.max( body.scrollHeight, body.offsetHeight, 
    //     html.clientHeight, html.scrollHeight, html.offsetHeight );
    //   window.parent.postMessage(["setHeight", height], "*");
    //   console.log('sergeight',height)


    var x = window.matchMedia("(min-width: 992px)")
    this.myFunction(x, length) // Call listener function at run time
    x.addListener(this.myFunction) // Attach listener function on state changes


  }

  myFunction(x, length) {
    var offsetHeight = 2500;
    try {
      offsetHeight = $('#mainContentDIV')[0].offsetHeight;
    } catch (error) {

    }
    //if (x.matches) { // If media query matches
    // var headerHeight = document.getElementById("headerDiv").offsetHeight;
    //console.log('headerHeight',headerHeight);
    // window.parent.postMessage(["setHeight", offsetHeight], "*");
    //} else {
    // var headerHeight = document.getElementById("headerDiv").offsetHeight;
    //console.log('MobileheaderHeight',headerHeight);


    // window.parent.postMessage(["setHeight", (offsetHeight * Math.ceil(length))+headerHeight], "*");
    // }
  }

  componentDidUpdate() {
    try {
      window.screen.orientation.addEventListener('change', function (e) {

        window.setTimeout(function () {
          //insert logic with height or width calulations here.
          let offsetHeight = 2500;

          offsetHeight = $('#mainContentDIV')[0].offsetHeight;

          console.log('window.screen.orientation.addEventListener', offsetHeight)
          window.parent.postMessage(["setHeight", offsetHeight], "*");
        }, 200);

      })
    } catch (error) {
     
    }
    try{
      window.addEventListener('resize', function (e) {

        window.setTimeout(function () {
          //insert logic with height or width calulations here.
          let offsetHeight = 2500;

          offsetHeight = $('#mainContentDIV')[0].offsetHeight;

          console.log('window.screen.orientation.addEventListener', offsetHeight)
          window.parent.postMessage(["setHeight", offsetHeight], "*");
        }, 200);

      })
    }catch(error){

    }
    var offsetHeight = 2500;
    try {

      offsetHeight = $('#mainContentDIV')[0].offsetHeight;
    } catch (error) {

    }
    console.log('mainContentDIV setHeight', offsetHeight)
    window.parent.postMessage(["setHeight", offsetHeight], "*");
  }
  componentDidMount() {
    // console.log("window.location.search : ",window.location.href)
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let company_id = params.companyId; // "some_value"
    // //console.log("company_id : ",company_id)

    let iframe = false;
    if (params.iframe) {
      iframe = params.iframe;
    }
    this.setState({ iframe: iframe })
    const dataState = {
      priceFrom: 0,
      lease: '',
      sortOrder: '',
      sortOrders: '',
      start: 1,
      pageSize: pageSizeStatic,
      sortColumn: "",
      sortColumnDirection: 'asc',
      searchValue: '',
      PropertyTypeId: 0,
      PriceUnder: 0,
      MarketingMaterialId: 0,
    }
    getList(dataState, company_id).then((filterData) => {
      this.setState({ propertyListings: filterData.data.data })
      this.setState({ headerData: filterData.data.company })
      this.setState({ isPreviousPage: filterData.data.isPreviousPage })
      this.setState({ isNextPage: filterData.data.isNextPage })
      this.setState({ recordsTotal: filterData.data.recordsTotal })
      this.setState({ paginationText: filterData.data.paginationText })
      this.setState({ filter: dataState })
      this.applyIframeHeight(filterData.data.data.length);
    })

    getAllFilter().then((filterData) => {
      this.setState({ filterListings: filterData })
    })
  }
  updateFilter = filter => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let company_id = params.companyId;
    getList(filter, company_id).then((filterData) => {
      this.setState({ propertyListings: filterData.data.data })
      this.setState({ headerData: filterData.data.company })
      this.setState({ isPreviousPage: filterData.data.isPreviousPage })
      this.setState({ isNextPage: filterData.data.isNextPage })
      this.setState({ recordsTotal: filterData.data.recordsTotal })
      this.setState({ paginationText: filterData.data.paginationText })
      this.applyIframeHeight(filterData.data.data.length);
      this.setState({ page: 1 })
      this.setState({ filter: filter })
    })
  }
  loadPrevious = (setLoading) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var dataState = {
      priceFrom: 0,
      lease: '',
      sortOrder: '',
      sortOrders: '',
      start: --this.state.page,
      pageSize: pageSizeStatic,
      sortColumn: this.state.filter.sortColumn,
      sortColumnDirection: 'asc',
      searchValue: this.state.filter.searchValue,
      PropertyTypeId: this.state.filter.PropertyTypeId,
      PriceUnder: 0,
      MarketingMaterialId: this.state.filter.MarketingMaterialId,
    }
    let company_id = params.companyId;
    getList(dataState, company_id).then((filterData) => {
      //let newList = [...this.state.propertyListings, ...filterData.data.data];
      this.setState({ propertyListings: filterData.data.data })
      this.setState({ headerData: filterData.data.company })
      this.setState({ isPreviousPage: filterData.data.isPreviousPage })
      this.setState({ isNextPage: filterData.data.isNextPage })

      this.setState({ recordsTotal: filterData.data.recordsTotal })
      this.setState({ paginationText: filterData.data.paginationText })
      setLoading(false);
      this.applyIframeHeight(filterData.data.data.length);

    })
  }
  loadMore = (setLoading) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var dataState = {
      priceFrom: 0,
      lease: '',
      sortOrder: '',
      sortOrders: '',
      start: ++this.state.page,
      pageSize: pageSizeStatic,
      sortColumn: this.state.filter.sortColumn,
      sortColumnDirection: 'asc',
      searchValue: this.state.filter.searchValue,
      PropertyTypeId: this.state.filter.PropertyTypeId,
      PriceUnder: 0,
      MarketingMaterialId: this.state.filter.MarketingMaterialId,
    }
    let company_id = params.companyId;
    getList(dataState, company_id).then((filterData) => {
      //let newList = [...this.state.propertyListings, ...filterData.data.data];
      this.setState({ propertyListings: filterData.data.data })
      this.setState({ headerData: filterData.data.company })
      this.setState({ isPreviousPage: filterData.data.isPreviousPage })
      this.setState({ isNextPage: filterData.data.isNextPage })
      this.setState({ recordsTotal: filterData.data.recordsTotal })
      this.setState({ paginationText: filterData.data.paginationText })
      setLoading(false);

      this.applyIframeHeight(filterData.data.data.length);
    })
  }
  render() {
    const { children } = this.props
    const { propertyListings, filterListings, headerData, filter, page, isPreviousPage, isNextPage, iframe, recordsTotal, paginationText } = this.state
    // const {  searchValue, PropertyTypeId, PriceUnder, MarketingMaterialId, sortColumn ,start,pageSize } = this.stateList

    const filteredListings = PropertyListingsProvider.applyFilter(
      propertyListings,
      filter
    )
    return (
      <PropertyListingsContext.Provider
        value={{
          paginationText: paginationText,
          recordsTotal: recordsTotal,
          iframe: iframe,
          isPreviousPage: isPreviousPage,
          isNextPage: isNextPage,
          filterListings: filterListings,
          propertyListings: propertyListings,
          headerData: headerData,
          page: page,
          updateFilter: this.updateFilter,
          loadPrevious: this.loadPrevious,
          loadMore: this.loadMore
        }}
      >
        {children}
      </PropertyListingsContext.Provider>
    )
  }
}

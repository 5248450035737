import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }

  render() {
    if (!this.props.google) {
      return <div>Loading...</div>;
    }

    return (
      <div
        style={{
          position: "relative",
          height: "500px"
        }}
      >
        {/* initialCenter={this.props.center}  */}
        <Map initialCenter={this.props.center}  center={this.props.center} style={{}} google={this.props.google} zoom={16}>
        <Marker
          // title={'The marker`s title will appear as a tooltip.'}
          // name={'SOMA'}
          position={this.props.center} />
        <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  v: "3.30"
})(MapContainer);

import * as React from 'react'
// import styles from './styles.module.css'

function Footer() {
  return (
  <footer id="footerDiv" className="text-center text-bold  footer vertical_align_center mt-3">
        Property Website powered by &nbsp;<a target="_blank" href={"https://crebuilder.com"}><img style={{width:'90px'}} src={'/images/footer_logo.png'}/></a>
  </footer>
  )

}



export default Footer

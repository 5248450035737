import * as React from 'react'

function BaseLayout({ children }) {
  return (
    <div className="pageContent">
      <main role="main" id="mainContentDIV">
        {children}
      </main>
    </div>
  )
}

export default BaseLayout

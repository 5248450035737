import * as React from 'react'
import classnames from 'classnames'
import { useNavigate, } from '@reach/router'
// import restApi from "../../hooks/rest-apis/restApis";
// import {
//   useLocation,
//   useNavigate,
// } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Listing({ iframe,listing }) {
// console.log("listing : ",listing)
  const navigate = useNavigate();


  if (!listing) {
    return null
  }
  function goToDetail (){
    navigate(`/details/${marketingMaterialAutoId}/${iframe}`);
  };
  function openDetailModel(params) {
    
  }
  function setContactCardHeight(){
    var maxHeight = 0
	$('.custom_column').children().each( function(index){
    $(this).height()
	if($(this).height() > maxHeight){
	  maxHeight = $(this).height()
	  }
	  $('.custom_column').children().height(maxHeight)
	})
  }
  const { marketingMaterialAutoId, autogeneratedimagename, title, buildingSF, price, spacesTotal,propertyAddress,city,state,marketingMaterialId  } = listing
  const columnClasses = classnames('custom_column', 'col-4', 'col-md-6' , 'col-sm-6', 'col-xs-12')
  const cardClasses = classnames('card', 'card_custom')
{setContactCardHeight()}
  return (
    <div className={columnClasses} style={{ margin: '1rem 0' }}>
      <div className={cardClasses} onClick={goToDetail}>
        <div className="card-image border_radius_2 img_rep_block">
          <img className="img-responsive border_radius_2 listing_thumb" src={`${process.env.REACT_APP_BASEURI+autogeneratedimagename}`}  />
        </div>
        <div className="card-header" >
          <div className="card_custom_title theme_color" >{title}</div>
          <p className="card_custom_subtitle" dangerouslySetInnerHTML={{ __html: propertyAddress }}></p>
          <p className="materialstatus">{marketingMaterialId==3 ? 'For Lease' : 'For Sale'}</p>

          
          <p className="p-listing text-bold price">{price}</p>
          <p className="p-listing">{buildingSF} Building</p>
          <p className="p-listing">{spacesTotal}</p>
        </div>
      </div>
    </div>
  )
}

export default Listing 

import axios from 'axios';
export default function restApi() {
  const baseURL = `${process.env.REACT_APP_API_BASEURI}`
  const getList = (dataObj,company_id) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('start', dataObj.start || 1);
    data.append('pageSize', dataObj.pageSize);
    data.append('sortColumn', dataObj.sortColumn);
    data.append('sortColumnDirection', dataObj.sortColumnDirection);
    data.append('searchValue', dataObj.searchValue);
    data.append('PropertyTypeId', dataObj.PropertyTypeId || 0);
    data.append('PriceUnder', dataObj.PriceUnder || 0);
    data.append('MarketingMaterialId', dataObj.MarketingMaterialId || 0);
    
    // console.log("company_id : ",company_id)
    var config = {
      method: 'post',
      url: baseURL + 'Index/'+company_id,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data
    };
    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getAllFilter = () => {
    var config = {
      method: 'get',
      url: baseURL + 'getListingFilter',
    };
    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getDetails = (id) => {
    var config = {
      method: 'get',
      url: baseURL + 'Detail/' + id,
    };
    return axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // const getHeader = (id) => {
  //   var FormData = require('form-data');
  //   var data = new FormData();
  //   data.append('id', id);
  //   var config = {
  //     method: 'post',
  //     url: baseURL + 'CompanyHeader',
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     data: data
  //   };
  //   return axios(config)
  //     .then(function (response) {
  //       return response.data;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  return {
    getList,
    getAllFilter,
    getDetails,
    // getHeader
  }
}